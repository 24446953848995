import React from 'react'
import '../styles/blog.css';
import foodImage from '../../public/banner3.png';
import waterFilter from "../../public/waterfilter.jpg"
import doubleLayerFilter from "../../public/doubleLayerFilter.jpg"
import juicer from "../../public/juicer.jpg"
import idlyMaker from "../../public/idlyMaker.jpg"
import cover from "../../public/covers.webp"


function BlogPage() {
  const data = [{ name: "WaterScience Kitchen Tap Filter for Mud & Sediments | For Kitchen & Wash Basin Taps | Tap filter for bathroom | With 2 Replacement Cartridges", image: waterFilter, link: 'https://amzn.to/49ZjMCU' },
  { name: "Fruit & Vegetable Steel Handle Juicer", image: juicer, link: "https://amzn.to/3wr0TuF" },
  { name: "Plastic Dhokla Idly Maker, Vegetable Steamer And Rice Cooker Set", image: idlyMaker, link: "https://amzn.to/3Wm1SH4" },
  { name: "XML Tap Water Purifier Filter Faucet Dust Chlorine Remover Water Softener for Drinking Cartridge Alkaline for Kitchen Wash Basin Sink Bathroom", image: doubleLayerFilter, link: "https://amzn.to/3WmGvpl" },

  ]
  return (
    <div className='blogPage'>
      <div className='categoryText'>Category</div>
      <h1 className='headingText'>Precision for Fitness: Digital Scales</h1>
      <div className='posterContainer'>By Shanthi | Published on April 29, 2024 | 2 mins read</div>
      <a href='https://amzn.to/4bioEUz'><img src={foodImage} className='mainImage' /></a>
      <div className='bodyContainer'>
        <div className='leftContainer'>
          <div className='blogText'>
            Discover precision in your culinary journey with the innovative <a href="https://amzn.to/4bioEUz"> Digital Kitchen Scale </a>, your ally in crafting healthy and fitness-conscious meals. No more guesswork – this scale guarantees accurate measurements, ensuring your recipes hit the mark every time.          </div>
          <br />
          <br />
          <div className='blogText'>
            Frustrated by inconsistent results despite your best efforts? Bid farewell to unreliable measuring techniques with our Digital Kitchen Scale. By weighing your ingredients, you'll achieve consistency, guaranteeing optimal taste, texture, and nutritional value in your dishes.          </div>
          <br />
          <br />
          <div className='blogText'>
            Designed for durability and ease, our scale boasts a robust ABS fiber body, a built-in LCD screen with backlighting, and convenient functions like Auto Zero and Auto Power Off. With unmatched accuracy up to 1 gram, this scale is indispensable for health enthusiasts and those striving for culinary excellence.

          </div>
          <br />
          <br />

          <h2 className='blogMainText'>Why You Need a Digital Kitchen Scale for Health & Fitness ?</h2>
          <div className='blogText'>
            <ul>
              <li><strong>Precision:</strong> Say goodbye to guesswork and achieve precise measurements for salt, flour, or any ingredient your recipe calls for.</li>
              <li><strong>Hassle-free calculation:</strong> No more struggling to scale recipes up or down – our scale simplifies the process, ensuring accuracy every time.</li>
              <li><strong>Time efficiency:</strong> Save time by avoiding mistakes caused by inconsistent volume measurements. Our scale streamlines your cooking and baking process.</li>
              <li><strong>Ease of use:</strong> With our scale, measuring ingredients like chopped tomatoes or boiled rice is a breeze. Say goodbye to multiple measuring cups cluttering your kitchen.</li>
              <li><strong>Cost-effective:</strong> Our Digital Kitchen Scale offers unbeatable value for its accuracy and convenience, making it a smart investment for any home cook.</li>
              <li><strong>Compact design:</strong> Its sleek, compact design fits seamlessly into any kitchen space, making storage a breeze.</li>
              <li><strong>Nutritional information:</strong> Some models even display nutritional data, helping you make healthier choices and maintain a balanced diet.</li>
            </ul>
          </div>
          <div className='breakLine' style={{ marginTop: "100px", border: "1px solid #D6DADC" }}></div>
          {/* <div className='thoughtText'>Good or Bad, we'd love Here Your Thoughts.</div> */}
          <div className='relatedArticle'>HERE ARE RELATED ARTICLE YOU MAY FIND INTERESTING </div>
          {/* {data.map((item) => {
            return ( */}
          <div className='postContent'>
            <div className='flex'>
              <img src={cover} className='bottomImage' />
              <div style={{ marginLeft: "5%", display: "flex", flexDirection: "column", justifyContent: "space-around", height: "203px" }}>
                <div className='categoryText' style={{ color: "#42526E" }}>PRODUCT - 4 Mins Read</div>
                <a href="https://www.amazon.in/?&tag=dealquest08-21" target="_blank" rel="noopener noreferrer" className='productText'>Exploring the Most Remarkable Hidden Gems on Amazon: Uncover Unique Finds!.</a>
                <div className='bottomBodyText'>Embark on an adventure through Amazon's treasure trove of ingenuity, revealing remarkable and distinctive products waiting to be discovered. From whimsical gadgets to practical inventions, each item offers a glimpse into the boundless creativity of sellers worldwide.</div>

              </div>
            </div>
            {/* <div className='breakLine'></div> */}
          </div>
          {/* )
          })} */}
        </div>
        <div className='rightContainer'>
          <div className='popularPost'>POPULAR PRODUCTS</div>
          {data.map((item) => {
            return (
              <div className='postContent'>
                <div className='flex'>
                  <img src={item.image} className='bodyImage' />
                  <div style={{ marginLeft: "5%" }}>
                    <div className='categoryText'>PRODUCT</div>
                    <a href={item.link} target="_blank" rel="noopener noreferrer" className='productText'>{item.name}</a>
                  </div>
                </div>
                <div className='breakLine'></div>
              </div>
            )
          })}
          <div className='getMoreContainer'>
            <div className='getMoreText'>Get More Done Together With Us</div>
            <div className='getMoreDetailText'>Transform shopping experience by exploring quality products and unbeatable deals</div>
            <button className='getStartedButton'>Coming Soon</button>
          </div>
        </div>

      </div>
    </div>
  )
}

export default BlogPage
