import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import BlogPage from './UI/pages/blogPage';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<BlogPage />} />

    </Routes>
  </BrowserRouter>
  );
}

export default App;
